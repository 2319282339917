<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2305 3.16888L12.2305 19.1689"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"></path>
    <path
      d="M8.5 7.52315C8.5 7.43414 8.52543 7.3464 8.57499 7.26884L11.8359 2.22316C11.9259 2.08456 12.0818 2 12.25 2C12.4182 2 12.5741 2.08456 12.664 2.22316L15.925 7.26884C16.0195 7.4157 16.0254 7.60136 15.9387 7.75331C15.8526 7.9059 15.6889 8 15.5109 8L8.98904 8C8.81103 8 8.64736 7.9059 8.56129 7.75331C8.52021 7.68147 8.5 7.60199 8.5 7.52315"
      fill="currentColor"></path>
    <path
      d="M8.5 15.4768C8.5 15.5659 8.52543 15.6536 8.57499 15.7312L11.8359 20.7768C11.9259 20.9154 12.0818 21 12.25 21C12.4182 21 12.5741 20.9154 12.664 20.7768L15.925 15.7312C16.0195 15.5843 16.0254 15.3986 15.9387 15.2467C15.8526 15.0941 15.6889 15 15.5109 15H8.98904C8.81103 15 8.64736 15.0941 8.56129 15.2467C8.52021 15.3185 8.5 15.398 8.5 15.4768"
      fill="currentColor"></path>
  </svg>
</template>
