<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7485 11.3002L11.7485 20.2502"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7495 11.3003L11.7485 3.36329L6.74751 11.3003L16.7495 11.3003Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
