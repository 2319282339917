<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M12.2306 3.16998L12.2306 20.17"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"></path>
    <path
      d="M15.6984 6.30435C15.6984 6.22275 15.6757 6.14232 15.6315 6.07122L12.7191 1.44602C12.6387 1.31897 12.4995 1.24145 12.3493 1.24145C12.199 1.24145 12.0598 1.31897 11.9795 1.44602L9.06706 6.07122C8.98262 6.20585 8.97738 6.37603 9.05483 6.51532C9.13171 6.6552 9.27788 6.74146 9.43687 6.74146H15.2617C15.4207 6.74146 15.5668 6.6552 15.6437 6.51532C15.6804 6.44947 15.6984 6.37661 15.6984 6.30435"
      fill="currentColor"></path>
    <path
      d="M9.00004 16.4371C9.00004 16.5187 9.02275 16.5991 9.06701 16.6702L11.9794 21.2954C12.0598 21.4225 12.199 21.5 12.3492 21.5C12.4995 21.5 12.6387 21.4225 12.719 21.2954L15.6314 16.6702C15.7159 16.5356 15.7211 16.3654 15.6437 16.2261C15.5668 16.0863 15.4206 16 15.2616 16H9.43681C9.27782 16 9.13165 16.0863 9.05478 16.2261C9.01809 16.292 9.00004 16.3648 9.00004 16.4371"
      fill="currentColor"></path>
    <path
      opacity="0.4"
      d="M19.21 11.1468L4.21002 11.1468"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"></path>
    <path
      d="M17.4371 14.6984C17.5187 14.6984 17.5991 14.6757 17.6702 14.6314L22.2954 11.719C22.4225 11.6386 22.5 11.4995 22.5 11.3492C22.5 11.199 22.4225 11.0598 22.2954 10.9794L17.6702 8.067C17.5356 7.98256 17.3654 7.97732 17.2261 8.05477C17.0863 8.13165 17 8.27782 17 8.4368V14.2616C17 14.4206 17.0863 14.5668 17.2261 14.6436C17.292 14.6803 17.3648 14.6984 17.4371 14.6984"
      fill="currentColor"></path>
    <path
      d="M7.30422 14.6984C7.22263 14.6984 7.1422 14.6757 7.0711 14.6315L2.4459 11.7191C2.31885 11.6387 2.24133 11.4995 2.24133 11.3493C2.24133 11.199 2.31885 11.0598 2.4459 10.9795L7.0711 8.06706C7.20573 7.98262 7.37591 7.97738 7.5152 8.05483C7.65508 8.13171 7.74133 8.27788 7.74133 8.43687L7.74133 14.2617C7.74133 14.4207 7.65508 14.5668 7.5152 14.6437C7.44934 14.6804 7.37649 14.6984 7.30422 14.6984"
      fill="currentColor"></path>
  </svg>
</template>
