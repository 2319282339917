<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 2V5.5M15.5 19.5H7C6.44772 19.5 6 19.0523 6 18.5V5.5M2 5.5H6"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round" />
    <path
      d="M9 5.5H18C18.5523 5.5 19 5.94772 19 6.5V19.5M19 23.5V19.5M19 19.5H23"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round" />
  </svg>
</template>
