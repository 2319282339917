<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.58532 14.2054C0.153838 6.91826 3.36958 4.73963 3.65413 4.38547C6.72284 1.27315 7.19885 2.68979 9.6096 5.13672C11.6406 7.20803 9.00771 8.19539 10.0867 10.6531"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M12.4904 13.3826C15.7706 16.0763 16.6957 12.2235 19.0192 14.5846C21.4162 17.0315 22.7945 17.5252 19.7551 20.6268C19.3977 20.938 17.0952 24.4689 9.19971 16.7417"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M4.49988 21.5002L19.4999 5.50021"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
