<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.25 12.2744L19.25 12.2744"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
