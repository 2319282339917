<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7575 22.4444L10.7574 22.4444C10.2923 22.3586 9.72423 22.1338 9.22795 21.9022L9.02518 22.3368L9.22795 21.9022C8.78207 21.6942 8.5 21.2228 8.5 20.6832V10.5378C8.5 9.70942 9.17157 9.03784 10 9.03784H11.3333C12.1618 9.03784 12.8333 9.70942 12.8333 10.5378V19.8285C12.8333 20.7638 13.3469 21.5791 14.1074 22.0078C14.0634 22.0216 14.0189 22.0353 13.974 22.049C12.9126 22.3712 11.6789 22.6145 10.7575 22.4444Z"
      stroke="currentColor" />
    <path
      d="M10.5 1.5C11.6046 1.5 12.5 2.39543 12.5 3.5C12.5 4.60457 11.6046 5.5 10.5 5.5C9.39543 5.5 8.5 4.60457 8.5 3.5C8.5 2.39543 9.39543 1.5 10.5 1.5Z"
      stroke="currentColor" />
  </svg>
</template>
