<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5, 3H4.5C3.671575 3 3 3.671575 3 4.5V19.5C3 20.32845 3.671575 21 4.5 21H19.5C20.32845 21 21 20.32845 21 19.5V4.5C21 3.671575 20.32845 3 19.5 3Z"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5" />
    <path
      d="M16 3H8V8H16V3Z"
      fill="none"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path d="M8.5 18H15.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
<script setup></script>
