const GeoRoutes = (prefix) => [
  {
    path: 'Countries',
    name: prefix + '.countries',
    meta: { auth: true, name: 'Countries', isBanner: true, banner: { title: 'Paises', description: '' } },
    component: () => import('@/views/geo/CountriesIndex.vue')
  },
  {
    path: 'Countries/add',
    name: prefix + '.country-add',
    meta: { auth: true, name: 'Country add', isBanner: true, banner: { title: 'Añadir País', description: '' } },
    component: () => import('@/views/geo/FormCountry.vue')
  },
  {
    path: 'Countries/:id',
    name: prefix + '.country-edit',
    meta: { auth: true, name: 'Country edit', isBanner: true, banner: { title: 'Editar País', description: '' } },
    component: () => import('@/views/geo/FormCountry.vue'),
    props: (route) => ({ id: Number(route.params.id) })
  }
]

export default GeoRoutes
