<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M16.34 1.99976H7.67C4.28 1.99976 2 4.37976 2 7.91976V16.0898C2 19.6198 4.28 21.9998 7.67 21.9998H16.34C19.73 21.9998 22 19.6198 22 16.0898V7.91976C22 4.37976 19.73 1.99976 16.34 1.99976Z"
      fill="currentColor"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.52124 10.8035C6.86024 10.8035 6.32324 11.3405 6.32324 11.9995C6.32324 12.6595 6.86024 13.1975 7.52124 13.1975C8.18224 13.1975 8.71924 12.6595 8.71924 11.9995C8.71924 11.3405 8.18224 10.8035 7.52124 10.8035ZM12.0002 10.8035C11.3392 10.8035 10.8022 11.3405 10.8022 11.9995C10.8022 12.6595 11.3392 13.1975 12.0002 13.1975C12.6612 13.1975 13.1982 12.6595 13.1982 11.9995C13.1982 11.3405 12.6612 10.8035 12.0002 10.8035ZM15.2817 11.9995C15.2817 11.3405 15.8187 10.8035 16.4797 10.8035C17.1407 10.8035 17.6777 11.3405 17.6777 11.9995C17.6777 12.6595 17.1407 13.1975 16.4797 13.1975C15.8187 13.1975 15.2817 12.6595 15.2817 11.9995Z"
      fill="currentColor"></path>
  </svg>
</template>
