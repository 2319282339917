<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" viewBox="0 0 24 24" fill="none">
    <path
      d="M4 3C4 1.89543 4.89543 1 6 1H13.0801C13.664 1 14.2187 1.25513 14.5986 1.69841L19.5185 7.43822C19.8292 7.80071 20 8.26239 20 8.73981V21C20 22.1046 19.1046 23 18 23H6C4.89543 23 4 22.1046 4 21V3Z"
      fill="currentColor"
      fill-opacity="0.4"></path>
    <path
      d="M13.0801 1H6C4.89543 1 4 1.89543 4 3V21C4 22.1046 4.89543 23 6 23H18C19.1046 23 20 22.1046 20 21V8.73981M13.0801 1C13.664 1 14.2187 1.25513 14.5986 1.69841L19.5185 7.43822C19.8292 7.80071 20 8.26239 20 8.73981M13.0801 1V5.73981C13.0801 7.39666 14.4232 8.73981 16.0801 8.73981H20"
      stroke="currentColor"></path>
    <path
      d="M9.15961 13.1986L9.15957 13.1986L9.15961 13.1986Z"
      fill="currentColor"
      fill-opacity="0.4"
      stroke="currentColor"
      stroke-linecap="round"></path>
    <line x1="12.975" y1="12.6181" x2="11.2497" y2="18.6566" stroke="currentColor" stroke-linecap="round"></line>
    <path
      d="M15.1037 17.8012C15.1037 17.8012 15.1037 17.8013 15.1036 17.8014L15.1037 17.8013L15.1037 17.8012Z"
      fill="currentColor"
      fill-opacity="0.4"
      stroke="currentColor"
      stroke-linecap="round"></path>
  </svg>
</template>
