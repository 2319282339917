<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 4.5H6V17.5C6 18.0523 6.44772 18.5 7 18.5H19V5.5C19 4.94772 18.5523 4.5 18 4.5Z"
      fill="currentColor"></path>
    <path
      d="M19 22.5V5.5C19 4.94772 18.5523 4.5 18 4.5H6H2M23 18.5H7C6.44772 18.5 6 18.0523 6 17.5V1"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"></path>
  </svg>
</template>
