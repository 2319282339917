<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M18.8089 9.021C18.3574 9.021 17.7594 9.011 17.0149 9.011C15.199 9.011 13.7059 7.508 13.7059 5.675V2.459C13.7059 2.206 13.503 2 13.2525 2H7.96337C5.49604 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59109 22 8.1703 22H16.0455C18.5059 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.298 9.012 20.0465 9.013C19.6238 9.016 19.1168 9.021 18.8089 9.021Z"
      fill="currentColor"></path>
    <path
      opacity="0.4"
      d="M16.0842 2.56737C15.7862 2.25637 15.2632 2.47037 15.2632 2.90137V5.53837C15.2632 6.64437 16.1742 7.55437 17.2802 7.55437C17.9772 7.56237 18.9452 7.56437 19.7672 7.56237C20.1882 7.56137 20.4022 7.05837 20.1102 6.75437C19.0552 5.65737 17.1662 3.69137 16.0842 2.56737Z"
      fill="currentColor"></path>
    <path
      d="M12.9349 12.9854L14.1559 11.7634C14.4469 11.4734 14.4469 11.0024 14.1559 10.7114C13.8649 10.4204 13.3939 10.4204 13.1029 10.7114L11.8819 11.9314L10.6609 10.7114C10.3699 10.4204 9.89792 10.4204 9.60692 10.7114C9.31592 11.0024 9.31592 11.4734 9.60692 11.7634L10.8289 12.9854L9.60692 14.2064C9.31592 14.4974 9.31592 14.9684 9.60692 15.2584C9.75292 15.4044 9.94292 15.4774 10.1339 15.4774C10.3249 15.4774 10.5149 15.4044 10.6609 15.2584L11.8819 14.0384L13.1029 15.2584C13.2479 15.4044 13.4389 15.4774 13.6299 15.4774C13.8199 15.4774 14.0109 15.4044 14.1559 15.2584C14.4469 14.9684 14.4469 14.4974 14.1559 14.2064L12.9349 12.9854Z"
      fill="currentColor"></path>
  </svg>
</template>
