<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M18.1085 8.94037C18.1085 8.94037 14.9368 4.5 10.1792 4.5C8.07626 4.5 6.05943 5.3354 4.57242 6.82242C3.0854 8.30943 2.25 10.3263 2.25 12.4292C2.25 14.5322 3.0854 16.549 4.57242 18.036C6.05943 19.5231 8.07626 20.3585 10.1792 20.3585C12.9354 20.3585 15.363 18.851 16.7839 16.9429"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"></path>
    <path
      d="M19.9876 12.7139C19.9026 12.7198 19.8173 12.7029 19.7402 12.6639L14.7222 10.0921C14.5844 10.0211 14.494 9.88782 14.4836 9.73793C14.4733 9.58804 14.5444 9.44359 14.6711 9.35425L19.2866 6.11519C19.421 6.02124 19.5979 6.00373 19.7483 6.07095C19.8992 6.13755 19.9992 6.27715 20.0102 6.43576L20.4126 12.2466C20.4236 12.4053 20.3439 12.5573 20.2035 12.6441C20.1375 12.6854 20.0629 12.7087 19.9876 12.7139"
      fill="currentColor"></path>
  </svg>
</template>
