<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3716 6.45056C15.3212 5.96422 15.2687 5.45963 15.1396 4.95611C14.7879 3.75153 13.8136 3.00001 12.7691 3.00001C12.1864 2.99786 11.4494 3.35644 11.0316 3.71932L7.56822 6.61697H5.75517C4.42084 6.61697 3.34792 7.6444 3.1453 9.12705C2.97313 10.5506 2.93114 13.2379 3.1453 14.8042C3.33112 16.3706 4.35575 17.383 5.75517 17.383H7.56822L11.0988 20.3236C11.461 20.6382 12.1003 20.9989 12.6882 20.9989C12.726 21 12.7596 21 12.7932 21C13.8577 21 14.7952 20.2206 15.1469 19.0192C15.2802 18.5082 15.3264 18.0293 15.3716 17.5666L15.4188 17.1082C15.5994 15.6213 15.5994 8.36908 15.4188 6.89288L15.3716 6.45056Z"
      fill="currentColor"></path>
    <path
      opacity="0.4"
      d="M19.4263 6.49467C19.1397 6.0706 18.5665 5.96538 18.1476 6.25848C17.7319 6.55479 17.629 7.14097 17.9177 7.56612C18.7208 8.74923 19.1628 10.3231 19.1628 12.0001C19.1628 13.676 18.7208 15.251 17.9177 16.4341C17.629 16.8592 17.7319 17.4454 18.1476 17.7417C18.302 17.8512 18.4836 17.9092 18.6715 17.9092C18.9728 17.9092 19.2542 17.7578 19.4263 17.5055C20.4405 16.0121 21 14.0571 21 12.0001C21 9.94307 20.4405 7.98805 19.4263 6.49467Z"
      fill="currentColor"></path>
  </svg>
</template>
