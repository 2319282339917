<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 15.5L12 8.5L19 15.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
