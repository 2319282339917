const StatisticsRoutes = (prefix) => [
  {
    path: 'types',
    name: prefix + '.types',
    meta: { auth: true, name: 'Types', isBanner: true, banner: { title: 'Tipos de Indicadores', description: '' } },
    component: () => import('@/views/indicators-types/IndexPage.vue')
  },
  {
    path: 'types/add',
    name: prefix + '.types-add',
    meta: {
      auth: true,
      name: 'Types add',
      isBanner: true,
      banner: { title: 'Añadir Tipo de Indicadores', description: '' }
    },
    component: () => import('@/views/indicators-types/FormIndicatorType.vue')
  },
  {
    path: 'types/:id',
    name: prefix + '.types-edit',
    meta: {
      auth: true,
      name: 'Types edit',
      isBanner: true,
      banner: { title: 'Editar Tipo de Indicadores', description: '' }
    },
    component: () => import('@/views/indicators-types/FormIndicatorType.vue'),
    props: (route) => ({ id: Number(route.params.id) })
  },
  {
    path: 'indicators',
    name: prefix + '.indicators',
    meta: { auth: true, name: 'Indicators', isBanner: true, banner: { title: 'Indicadores', description: '' } },
    component: () => import('@/views/indicators/IndexPage.vue')
  },
  {
    path: 'indicators/add',
    name: prefix + '.indicators-add',
    meta: {
      auth: true,
      name: 'Indicators add',
      isBanner: true,
      banner: { title: 'Añadir Indicador', description: '' }
    },
    component: () => import('@/views/indicators/FormIndicator.vue')
  },
  {
    path: 'indicators/:id',
    name: prefix + '.indicators-edit',
    meta: {
      auth: true,
      name: 'Indicators edit',
      isBanner: true,
      banner: { title: 'Editar Indicador', description: '' }
    },
    component: () => import('@/views/indicators/FormIndicator.vue'),
    props: (route) => ({ id: Number(route.params.id) })
  }
]

export default StatisticsRoutes
