<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 10.6699C2 5.88166 5.84034 2 10.5776 2C12.8526 2 15.0343 2.91344 16.6429 4.53936C18.2516 6.16529 19.1553 8.37052 19.1553 10.6699C19.1553 15.4582 15.3149 19.3399 10.5776 19.3399C5.84034 19.3399 2 15.4582 2 10.6699ZM19.0134 17.6543L21.568 19.7164H21.6124C22.1292 20.2388 22.1292 21.0858 21.6124 21.6082C21.0955 22.1306 20.2576 22.1306 19.7407 21.6082L17.6207 19.1785C17.4203 18.9766 17.3076 18.7024 17.3076 18.4164C17.3076 18.1304 17.4203 17.8562 17.6207 17.6543C18.0072 17.2704 18.6268 17.2704 19.0134 17.6543Z"
      fill="currentColor"></path>
    <path
      d="M14.1691 11.3318H11.3259V14.1682C11.3259 14.6292 10.9555 15 10.495 15C10.0345 15 9.66407 14.6292 9.66407 14.1682V11.3318H6.83092C6.37041 11.3218 6 10.951 6 10.49C6 10.2685 6.0901 10.059 6.24027 9.89866C6.40044 9.74833 6.61068 9.65813 6.83092 9.65813H9.66407V6.83185C9.66407 6.37082 10.0345 6 10.495 6C10.9555 6 11.3259 6.37082 11.3259 6.83185V9.65813H14.1691C14.6296 9.65813 15 10.029 15 10.49C15 10.951 14.6296 11.3218 14.1691 11.3318"
      fill="white"></path>
  </svg>
</template>
