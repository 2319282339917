<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2515 12.6998V3.74976"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.25049 12.6997L12.2515 20.6367L17.2525 12.6997H7.25049Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
