<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7261 4.25L11.7261 19.25"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M5.70149 10.2998L11.7255 4.2498L17.7505 10.2998"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
