const IARoutes = (prefix) => [
  {
    path: 'ia',
    name: prefix + '.ia',
    meta: { auth: true, name: 'ia', isBanner: true, banner: { title: 'IA configuration', description: '' } },
    component: () => import('@/views/gpt/IndexPage.vue')
  }
]

export default IARoutes
