<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8311 12.2305L3.83112 12.2305"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M15.9167 7.46121L20.7063 12.2302L15.9167 17"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M7.78958 7.46121L3 12.2302L7.78958 17"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
