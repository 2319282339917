<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.21433 13.1328L14.0624 8.91355C14.2158 8.81954 14.4122 8.82854 14.5558 8.93955C15.2064 9.44057 15.7877 9.9486 16.1755 10.3756C16.1755 10.3756 16.5096 10.7176 16.6542 10.9346C16.8877 11.2307 17 11.6177 17 11.9937C17 12.4157 16.8769 12.8147 16.6317 13.1338C16.576 13.1908 16.364 13.4418 16.1638 13.6468C14.9954 14.9228 11.9455 17.031 10.3414 17.67C10.1079 17.773 9.48466 17.988 9.16131 18C8.84967 18 8.54879 17.932 8.25962 17.783C7.90403 17.578 7.62561 17.26 7.46931 16.8829C7.36869 16.6209 7.21336 15.8349 7.21336 15.8119C7.11274 15.2509 7.03751 14.4628 7.00039 13.5428C6.99355 13.3778 7.07659 13.2178 7.21433 13.1328Z"
      fill="currentColor"></path>
    <path
      opacity="0.4"
      d="M7.67252 10.8603C7.37066 11.0473 6.99064 10.8083 7.00529 10.4493C7.04144 9.60721 7.10396 8.86518 7.18016 8.31315C7.19188 8.30115 7.34721 7.3221 7.52598 6.99108C7.83762 6.37605 8.44916 6.00003 9.10662 6.00003H9.16132C9.5853 6.01103 10.487 6.38705 10.487 6.41005C10.9412 6.59906 11.5342 6.91908 12.1721 7.3041C12.4594 7.47811 12.4662 7.90513 12.179 8.08214L7.67252 10.8603Z"
      fill="currentColor"></path>
  </svg>
</template>
