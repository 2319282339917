import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// Library Components
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue3-apexcharts'
import BootstrapVue3 from 'bootstrap-vue-3'
import CounterUp from 'vue3-autocounter'
import Vue3Datatable from '@bhplugin/vue3-datatable'
import 'aos/dist/aos.css'
import '/node_modules/flag-icons/css/flag-icons.min.css'
import '@bhplugin/vue3-datatable/dist/style.css'
import PrimeVue from 'primevue/config'

// Custom Components & Directives
import globalComponent from './plugins/global-components'
import globalDirective from './plugins/global-directive'
import globalMixin from './plugins/global-mixin'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
//import 'dotenv/config'

require('waypoints/lib/noframework.waypoints.min')

const app = createApp(App)
app.use(store).use(router)

// Library Components
app.use(PrimeVue)
app.use(VueSweetalert2)
app.use(Vue3Datatable)
app.use(VueApexCharts)
app.use(BootstrapVue3)
app.component('counter-up', CounterUp)
app.component('VueDatePicker', VueDatePicker)

// Custom Components & Directives
app.use(globalComponent)
app.use(globalDirective)
app.mixin(globalMixin)

app.mount('#app')

export default app
