export const CustomersRoutes = (prefix) => [
  {
    path: 'customers',
    name: prefix + '.subscriptions',
    meta: { auth: true, name: 'Customers', isBanner: true, banner: { title: 'Customers', description: '' } },
    component: () => import('@/views/customers/IndexPage.vue')
  },
  {
    path: 'customers/:id/:name/:expirationDate',
    name: prefix + '.subscriptions-edit',
    meta: {
      auth: true,
      name: 'Customers',
      isBanner: true,
      banner: { title: 'Actualizar Suscripción', description: '' }
    },
    component: () => import('@/views/customers/FormUpdateSubscription.vue'),
    props: (route) => ({
      id: Number(route.params.id),
      name: route.params.name,
      expirationDate: route.params.expirationDate
    })
  }
]
