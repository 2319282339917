<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M19.8311 12.2305L3.83112 12.2305"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"></path>
    <path
      d="M15.4768 8.5C15.5659 8.5 15.6536 8.52543 15.7312 8.57499L20.7768 11.8359C20.9154 11.9259 21 12.0818 21 12.25C21 12.4182 20.9154 12.5741 20.7768 12.664L15.7312 15.925C15.5843 16.0195 15.3986 16.0254 15.2467 15.9387C15.0941 15.8526 15 15.6889 15 15.5109V8.98904C15 8.81103 15.0941 8.64736 15.2467 8.56129C15.3185 8.52021 15.398 8.5 15.4768 8.5"
      fill="currentColor"></path>
    <path
      d="M7.52315 8.5C7.43414 8.5 7.3464 8.52543 7.26884 8.57499L2.22316 11.8359C2.08456 11.9259 2 12.0818 2 12.25C2 12.4182 2.08456 12.5741 2.22316 12.664L7.26884 15.925C7.4157 16.0195 7.60136 16.0254 7.75331 15.9387C7.9059 15.8526 8 15.6889 8 15.5109V8.98904C8 8.81103 7.9059 8.64736 7.75331 8.56129C7.68147 8.52021 7.60199 8.5 7.52315 8.5"
      fill="currentColor"></path>
  </svg>
</template>
