<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M7.916 22H16.084C19.623 22 22 19.724 22 16.335V7.665C22 4.276 19.623 2 16.084 2H7.916C4.378 2 2 4.277 2 7.666L2 16.335C2 19.724 4.378 22 7.916 22Z"
      fill="currentColor"></path>
    <path
      d="M12.8555 16.2792L16.6205 12.5312C16.9035 12.2492 16.9035 11.7502 16.6205 11.4672L12.8555 7.71918C12.5615 7.42718 12.0865 7.42818 11.7945 7.72218C11.5025 8.01618 11.5025 8.49018 11.7965 8.78318L14.2735 11.2502H7.91846C7.50346 11.2502 7.16846 11.5862 7.16846 12.0002C7.16846 12.4142 7.50346 12.7502 7.91846 12.7502H14.2735L11.7965 15.2162C11.6495 15.3632 11.5765 15.5552 11.5765 15.7482C11.5765 15.9392 11.6495 16.1312 11.7945 16.2772C12.0865 16.5702 12.5615 16.5712 12.8555 16.2792Z"
      fill="currentColor"></path>
  </svg>
</template>
