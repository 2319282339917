<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="11.7669"
      cy="11.7666"
      r="8.98856"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M18.0186 18.4851L21.5426 22"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
