<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse opacity="0.4" cx="10.5992" cy="10.6532" rx="8.59922" ry="8.65324" fill="currentColor"></ellipse>
    <path
      d="M20.6746 21.9553C20.3406 21.9445 20.023 21.807 19.7854 21.5705L17.749 19.1902C17.3124 18.7909 17.2767 18.1123 17.6689 17.6689V17.6689C17.8525 17.4831 18.1022 17.3787 18.3626 17.3787C18.6229 17.3787 18.8726 17.4831 19.0562 17.6689L21.6173 19.7181C21.9862 20.0957 22.1 20.6563 21.9079 21.1492C21.7158 21.6422 21.2536 21.9754 20.728 22L20.6746 21.9553Z"
      fill="currentColor"></path>
    <path
      d="M14.2355 11.2969H11.3432V14.1641C11.3432 14.6258 10.9657 15 10.5 15C10.0343 15 9.65679 14.6258 9.65679 14.1641V11.2969H6.76449C6.3301 11.2523 6 10.8894 6 10.4565C6 10.0236 6.3301 9.66069 6.76449 9.61609H9.64772V6.75786C9.69271 6.32724 10.0588 6 10.4955 6C10.9322 6 11.2982 6.32724 11.3432 6.75786V9.61609H14.2355C14.6699 9.66069 15 10.0236 15 10.4565C15 10.8894 14.6699 11.2523 14.2355 11.2969V11.2969Z"
      fill="currentColor"></path>
  </svg>
</template>
