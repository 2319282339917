import { createStore, createLogger } from 'vuex'
import setting from './setting'
import createPersistedState from 'vuex-persistedstate'
const debug = process.env.NODE_ENV !== 'production'
export default createStore({
  state: {
    shareOffcanvas: false,
    session: false,
    isLoggedIn: false
  },
  getters: {
    shareOffcanvas: (state) => state.shareOffcanvas,
    getSession: (state) => state.session,
    getIsLoggedIn: (state) => state.isLoggedIn
  },
  mutations: {
    openBottomCanvasCommit(state, payload) {
      state[payload.name] = payload.value
    },
    saveSession(state, payload) {
      state[payload.name] = payload.value
    },
    saveLoggedIn(state, payload) {
      state[payload.name] = payload.value
    }
  },
  actions: {
    openBottomCanvasAction({ commit }, payload) {
      commit('openBottomCanvasCommit', payload)
    },
    setSession({ commit }, payload) {
      commit('saveSession', payload)
    },
    setLoggedIn({ commit }, payload) {
      commit('saveLoggedIn', payload)
    }
  },
  modules: {
    setting: setting
  },
  strict: debug,
  plugins: debug
    ? [
        createLogger(),
        createPersistedState({
          storage: window.sessionStorage
        })
      ]
    : [
        createPersistedState({
          storage: window.sessionStorage
        })
      ]
})
