<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2744 19.75V4.75"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      d="M18.299 13.7002L12.275 19.7502L6.25 13.7002"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
