<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7 11.7488H3.75"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7002 16.7498L20.6372 11.7488L12.7002 6.74776V16.7498Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
</template>
