<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8672 7.21433L15.0865 14.0624C15.1805 14.2158 15.1715 14.4122 15.0605 14.5558C14.5594 15.2064 14.0514 15.7877 13.6244 16.1755C13.6244 16.1755 13.2824 16.5096 13.0654 16.6542C12.7693 16.8877 12.3823 17 12.0063 17C11.5843 17 11.1853 16.8769 10.8662 16.6317C10.8092 16.576 10.5582 16.364 10.3532 16.1638C9.07715 14.9954 6.96905 11.9455 6.33002 10.3414C6.22701 10.1079 6.012 9.48466 6 9.16131C6 8.84967 6.068 8.54879 6.21701 8.25962C6.42202 7.90403 6.74004 7.62561 7.11706 7.46931C7.37907 7.36869 8.16511 7.21336 8.18811 7.21336C8.74914 7.11274 9.53718 7.03751 10.4572 7.00039C10.6222 6.99355 10.7822 7.07659 10.8672 7.21433Z"
      fill="currentColor"></path>
    <path
      opacity="0.4"
      d="M13.14 7.67228C12.953 7.37041 13.192 6.9904 13.551 7.00505C14.393 7.0412 15.1351 7.10372 15.6871 7.17992C15.6991 7.19164 16.6781 7.34697 17.0092 7.52574C17.6242 7.83737 18.0002 8.44892 18.0002 9.10637V9.16108C17.9892 9.58506 17.6132 10.4867 17.5902 10.4867C17.4012 10.941 17.0812 11.534 16.6961 12.1719C16.5221 12.4591 16.0951 12.466 15.9181 12.1787L13.14 7.67228Z"
      fill="currentColor"></path>
  </svg>
</template>
