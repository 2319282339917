<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8582 7.55636C15.8764 7.68967 15.8935 7.81524 15.9027 7.98142L6.29038 17.5926H6.06807C4.66009 17.5926 3.63322 16.5976 3.44267 15.0522C3.23094 13.5068 3.27329 10.8605 3.44267 9.45274C3.64381 7.992 4.72361 6.98643 6.06807 6.98643H7.8995L11.393 4.12741C11.8164 3.76858 12.568 3.42986 13.1503 3.41821C14.2089 3.41821 15.1829 4.16022 15.5322 5.34575C15.6698 5.84219 15.7228 6.34074 15.7651 6.81601L15.8498 7.49451C15.8526 7.51542 15.8554 7.53598 15.8582 7.55636ZM14.881 13.7315C15.025 13.5907 15.3437 13.4912 15.4876 13.5272C15.8772 13.6267 15.9524 14.1846 15.9471 14.6228C15.9291 15.8951 15.8857 16.78 15.8179 17.3273L15.7703 17.7782L15.7695 17.7866C15.7242 18.2402 15.6774 18.7096 15.5437 19.2103C15.1902 20.3937 14.2448 21.1622 13.1714 21.1622C13.1354 21.1622 13.1004 21.1622 13.0644 21.1612C12.4716 21.1612 11.8269 20.8055 11.4617 20.4954L10.1606 19.4887C9.6673 19.1214 9.81233 18.534 10.0897 18.1942C10.2967 17.9413 12.7846 15.6581 14.0924 14.4578C14.5365 14.0502 14.8445 13.7675 14.881 13.7315Z"
      fill="currentColor"></path>
    <path
      d="M21.7275 3.26979C21.3538 2.90884 20.7801 2.91095 20.4201 3.27085L3.27035 20.4186C2.90936 20.7795 2.90936 21.3543 3.27352 21.7322C3.45984 21.9047 3.69062 22 3.92458 22C4.16383 22 4.40943 21.8994 4.57987 21.729L21.7286 4.58127C22.0906 4.21926 22.0906 3.63285 21.7275 3.26979Z"
      fill="currentColor"></path>
  </svg>
</template>
