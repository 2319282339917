<template>
  <!--Logo start-->
  <div class="logo-main">
    <img src="../../../assets/images/logo.svg" :width="size" />
  </div>
  <!--Logo End-->
</template>

<script>
export default {
  props: {
    color: { type: Boolean, default: false },
    src: { type: String, default: '../../../assets/images/logo.svg' },
    size: { type: Number, default: 120 }
  }
}
</script>
