<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse opacity="0.4" cx="10.5992" cy="10.6532" rx="8.59922" ry="8.65324" fill="currentColor"></ellipse>
    <path
      d="M20.6746 21.9553C20.3406 21.9445 20.023 21.807 19.7854 21.5705L17.749 19.1902C17.3124 18.7909 17.2767 18.1123 17.6689 17.6689V17.6689C17.8525 17.4831 18.1022 17.3787 18.3626 17.3787C18.6229 17.3787 18.8726 17.4831 19.0562 17.6689L21.6173 19.7181C21.9862 20.0957 22.1 20.6563 21.9079 21.1492C21.7158 21.6422 21.2536 21.9754 20.728 22L20.6746 21.9553Z"
      fill="currentColor"></path>
    <path
      d="M15 10.5844H6"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"></path>
  </svg>
</template>
