<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 1V4.5M15.5 18.5H7C6.44772 18.5 6 18.0523 6 17.5V4.5M2 4.5H6"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"></path>
    <path
      opacity="0.4"
      d="M9 4.5H18C18.5523 4.5 19 4.94772 19 5.5V18.5M19 22.5V18.5M19 18.5H23"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"></path>
  </svg>
</template>
