<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1328 16.7857L8.91355 9.93756C8.81954 9.78418 8.82854 9.58782 8.93955 9.44422C9.44057 8.7936 9.9486 8.21234 10.3756 7.82451C10.3756 7.82451 10.7176 7.49041 10.9346 7.34582C11.2307 7.11234 11.6177 7 11.9937 7C12.4157 7 12.8147 7.12309 13.1338 7.36829C13.1908 7.42398 13.4418 7.63597 13.6468 7.83623C14.9228 9.00461 17.031 12.0545 17.67 13.6586C17.773 13.8921 17.988 14.5153 18 14.8387C18 15.1503 17.932 15.4512 17.783 15.7404C17.578 16.096 17.26 16.3744 16.8829 16.5307C16.6209 16.6313 15.8349 16.7866 15.8119 16.7866C15.2509 16.8873 14.4628 16.9625 13.5428 16.9996C13.3778 17.0064 13.2178 16.9234 13.1328 16.7857Z"
      fill="currentColor"></path>
    <path
      opacity="0.4"
      d="M10.86 16.3277C11.047 16.6296 10.808 17.0096 10.449 16.995C9.60697 16.9588 8.86493 16.8963 8.31291 16.8201C8.3009 16.8084 7.32186 16.653 6.99084 16.4743C6.37581 16.1626 5.99979 15.5511 5.99979 14.8936V14.8389C6.01079 14.4149 6.38681 13.5133 6.40981 13.5133C6.59882 13.059 6.91884 12.466 7.30385 11.8281C7.47786 11.5409 7.90488 11.534 8.08189 11.8213L10.86 16.3277Z"
      fill="currentColor"></path>
  </svg>
</template>
