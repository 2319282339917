<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8397 20.1642V6.54639"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"></path>
    <path
      d="M20.9173 16.0681L16.8395 20.1648L12.7617 16.0681"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"></path>
    <path
      d="M6.91102 3.83276V17.4505"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"></path>
    <path
      d="M2.8335 7.92894L6.91127 3.83228L10.9891 7.92894"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"></path>
  </svg>
</template>
