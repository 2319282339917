<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M12 22.0002C6.485 22.0002 2 17.5142 2 12.0002C2 6.48624 6.485 2.00024 12 2.00024C17.514 2.00024 22 6.48624 22 12.0002C22 17.5142 17.514 22.0002 12 22.0002Z"
      fill="currentColor"></path>
    <path
      d="M13.4425 16.2208C13.2515 16.2208 13.0595 16.1478 12.9135 16.0018L9.42652 12.5318C9.28552 12.3908 9.20652 12.1998 9.20652 11.9998C9.20652 11.8008 9.28552 11.6098 9.42652 11.4688L12.9135 7.99683C13.2065 7.70483 13.6805 7.70483 13.9735 7.99883C14.2655 8.29283 14.2645 8.76783 13.9715 9.05983L11.0185 11.9998L13.9715 14.9398C14.2645 15.2318 14.2655 15.7058 13.9735 15.9998C13.8275 16.1478 13.6345 16.2208 13.4425 16.2208Z"
      fill="currentColor"></path>
  </svg>
</template>
