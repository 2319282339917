<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7609 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17.7826C18.8872 22 19.7826 21.1046 19.7826 20V9.4565H19.7826H15.2609C13.8802 9.4565 12.7609 8.33721 12.7609 6.9565V2ZM13.7609 6.9565V2.88757L18.6746 8.4565H15.2609C14.4324 8.4565 13.7609 7.78493 13.7609 6.9565Z"
      fill="currentColor
" />
    <path
      d="M9.86387 16.2421C10.0444 16.4138 10.0444 16.6921 9.86988 16.8697C9.77964 16.9585 9.66532 17 9.54499 17C9.43068 17 9.31636 16.9585 9.23213 16.8697L7.13236 14.8153C7.04813 14.7324 7 14.6199 7 14.5015C7 14.3831 7.04813 14.2706 7.13236 14.1877L9.23213 12.1332C9.40661 11.9556 9.68939 11.9556 9.86988 12.1332C10.0444 12.3049 10.0444 12.5891 9.86387 12.7608L8.08899 14.5015L9.86387 16.2421Z"
      fill="#232D42" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2446 11.1171C13.4471 11.1818 13.5622 11.3851 13.5017 11.5713L11.5298 17.64C11.4693 17.8262 11.2561 17.9248 11.0536 17.8601C10.8511 17.7954 10.736 17.5921 10.7965 17.4059L12.7684 11.3372C12.8289 11.151 13.0421 11.0524 13.2446 11.1171Z"
      fill="currentColor" />
    <path
      d="M14.1361 12.7579C13.9556 12.5862 13.9556 12.3079 14.1301 12.1303C14.2204 12.0415 14.3347 12 14.455 12C14.5693 12 14.6836 12.0415 14.7679 12.1303L16.8676 14.1847C16.9519 14.2676 17 14.3801 17 14.4985C17 14.6169 16.9519 14.7294 16.8676 14.8123L14.7679 16.8668C14.5934 17.0444 14.3106 17.0444 14.1301 16.8668C13.9556 16.6951 13.9556 16.4109 14.1361 16.2392L15.911 14.4985L14.1361 12.7579Z"
      fill="#232D42" />
  </svg>
</template>
