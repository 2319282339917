<template>
  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M19.3371 5.91772L21.7407 3.50518C21.9082 3.33698 22.0001 3.11407 22.0001 2.87798C22.0001 2.64189 21.9082 2.41898 21.7407 2.25078C21.4065 1.91641 20.8261 1.91641 20.4919 2.25078L18.0884 4.66434L15.6839 2.25078C15.3498 1.91641 14.7693 1.91641 14.4352 2.25078C14.2686 2.41898 14.1768 2.64189 14.1768 2.87798C14.1768 3.11407 14.2686 3.33698 14.4352 3.50518L16.8387 5.91772L14.4352 8.33027C14.2686 8.49847 14.1768 8.72138 14.1768 8.95747C14.1768 9.19356 14.2686 9.41647 14.4352 9.58467C14.7693 9.91904 15.3498 9.91904 15.6839 9.58467L18.0884 7.17111L20.4919 9.58467C20.6595 9.75186 20.8806 9.84406 21.1168 9.84406C21.353 9.84406 21.5741 9.75186 21.7407 9.58467C21.9082 9.41647 22.0001 9.19356 22.0001 8.95747C22.0001 8.72138 21.9082 8.49847 21.7407 8.33027L19.3371 5.91772Z"
      fill="currentColor"></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0317 12.9724C15.0208 16.9604 15.9258 12.3467 18.4656 14.8848C20.9143 17.3328 22.3216 17.8232 19.2192 20.9247C18.8306 21.237 16.3616 24.9943 7.6846 16.3197C-0.993478 7.644 2.76158 5.17244 3.07397 4.78395C6.18387 1.67385 6.66586 3.08938 9.11449 5.53733C11.6544 8.0765 7.04266 8.98441 11.0317 12.9724Z"
      fill="currentColor"></path>
  </svg>
</template>
